import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import PageContent from "@/main/page/content/PageContent"
import OrganizationGuestListRow from "@/organization/people/list/item/OrganizationGuestListRow"
import CommunityGuestListFloatingBar from "@/organization/people/page/guest/CommunityGuestListFloatingBar"
import CommunityGuestReportTableInviteFilter, {
  CommunityGuestReportTableInviteFilterState,
} from "@/organization/people/page/guest/CommunityGuestReportTableInviteFilter"
import CommunityGuestSelectAllCheckbox from "@/organization/people/page/guest/CommunityGuestSelectAllCheckbox"
import GuestsSortDropdown from "@/organization/people/page/guest/GuestsSortDropdown"
import { CommunityGuestReportTableMutation } from "@/organization/people/page/guest/__generated__/CommunityGuestReportTableMutation.graphql"
import {
  CommunityGuestReportTablePaginationQuery,
  GuestMembershipsOrderBy,
} from "@/organization/people/page/guest/__generated__/CommunityGuestReportTablePaginationQuery.graphql"
import { CommunityGuestReportTableQuery } from "@/organization/people/page/guest/__generated__/CommunityGuestReportTableQuery.graphql"
import { CommunityGuestReportTable_PaginationFragment$key } from "@/organization/people/page/guest/__generated__/CommunityGuestReportTable_PaginationFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { displayErrorToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoDropdown,
  DiscoTable,
  DiscoTableSkeletonWithHeader,
  DiscoText,
} from "@disco-ui"
import DiscoTableSearchInput, {
  DiscoTableSearchInputSkeleton,
} from "@disco-ui/table/header/search/DiscoTableSearchInput"
import {
  DiscoTableSortDropdownSkeleton,
  DiscoTableToolbarStateWithSort,
} from "@disco-ui/table/header/search/DiscoTableSortDropdown"
import { TableCell, useMediaQuery, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import {
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"
import { graphql } from "relay-runtime"

export type GuestCheckList = {
  guestMembershipId: GlobalID
  userMembershipId?: GlobalID
  userId: GlobalID
  email: string
}
export type OrderDirection = "ASC" | "DESC"
export type CommunityGuestReportTableToolbarState = DiscoTableToolbarStateWithSort &
  CommunityGuestReportTableInviteFilterState

const GUESTS_PER_PAGE = 10

const CommunityGuestReportTable = () => {
  const activeOrganization = useActiveOrganization()!
  const TESTID = "CommunityGuestReportTable"
  const [selectedMembershipIds, setSelectedMembershipIds] = useState<GuestCheckList[]>([])

  const [activePage, setActivePage] = useState(1)
  const theme = useTheme()
  const classes = useStyles()
  const isMobile = useIsMobile()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [toolbarState, setToolbarState] = useState<CommunityGuestReportTableToolbarState>(
    {
      search: "",
      hasBeenInvited: null,
      sort: {
        id: "none",
        order: { field: "joined_on" },
        title: "Sort",
      },
      direction: "DESC",
    }
  )
  const guestMembershipArgs = {
    search: toolbarState.search,
    orderBy: toolbarState.sort.order.field as GuestMembershipsOrderBy,
    direction: toolbarState.direction as OrderDirection,
    hasBeenInvited: toolbarState.hasBeenInvited,
  }
  const { organization } = useLazyLoadQuery<CommunityGuestReportTableQuery>(
    graphql`
      query CommunityGuestReportTableQuery(
        $id: ID!
        $search: String
        $orderBy: GuestMembershipsOrderBy
        $hasBeenInvited: Boolean
        $direction: OrderDirection
        $first: Int
        $after: String
        $last: Int
        $before: String
      ) {
        organization: node(id: $id) {
          ... on Organization {
            id
            ...CommunityGuestReportTable_PaginationFragment
              @arguments(
                orderBy: $orderBy
                direction: $direction
                hasBeenInvited: $hasBeenInvited
                search: $search
                first: $first
                after: $after
                last: $last
                before: $before
              )
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      first: GUESTS_PER_PAGE,
      ...guestMembershipArgs,
    },
    { fetchPolicy: "network-only" }
  )
  const response = usePaginationFragment<
    CommunityGuestReportTablePaginationQuery,
    CommunityGuestReportTable_PaginationFragment$key
  >(
    graphql`
      fragment CommunityGuestReportTable_PaginationFragment on Organization
      @refetchable(queryName: "CommunityGuestReportTablePaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
        search: { type: "String" }
        orderBy: { type: "GuestMembershipsOrderBy" }
        direction: { type: "OrderDirection" }
        hasBeenInvited: { type: "Boolean" }
      ) {
        id
        guestMemberships(
          search: $search
          orderBy: $orderBy
          direction: $direction
          hasBeenInvited: $hasBeenInvited
          first: $first
          after: $after
          last: $last
          before: $before
        ) @connection(key: "CommunityGuestReportTable__guestMemberships") {
          __id
          totalCount
          edges {
            cursor
            node {
              id
              ...OrganizationGuestListRow_Member
              email
              user {
                id
              }
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organization
  )
  useSubscribeToInvalidationState(
    response.data?.guestMemberships.__id ? [response.data.guestMemberships.__id] : [],
    refetchGuests
  )

  const createGuestCSVMutation =
    Relay.useAsyncMutation<CommunityGuestReportTableMutation>(
      graphql`
        mutation CommunityGuestReportTableMutation(
          $input: CreateGuestMembershipCSVDataInput!
        ) {
          response: createGuestMembershipCSVData(input: $input) {
            downloadCSVLink
            errors {
              field
              message
            }
          }
        }
      `
    )
  if (!response.data || !organization) return null

  const guestMemberships = Relay.connectionToArray(response.data?.guestMemberships)
  const cursorsList = response.data?.guestMemberships.edges.map((e) => e.cursor)

  const totalSelectedGuests = selectedMembershipIds.length
  const { totalCount } = response.data?.guestMemberships
  const isAllSelected = totalCount > 0 && totalSelectedGuests >= totalCount

  return (
    <>
      <DiscoTable
        activePage={activePage}
        setActivePage={setActivePage}
        rowsPerPage={GUESTS_PER_PAGE}
        testid={"CommunityGuestReportTable"}
        scrollable
        stickyFirstColumn={!isMobile}
        rows={guestMemberships.map((gm, index) => (
          <OrganizationGuestListRow
            key={gm.id}
            guestMembershipKey={gm}
            testid={`OrganizationGuestListRow.guest.${index}`}
            isSelected={isMembershipSelected({
              guestMembershipId: gm.id,
              userId: gm.user.id,
              email: gm.email || "",
            })}
            toggleSelected={() =>
              toggleSelectedMembership({
                guestMembershipId: gm.id,
                userId: gm.user.id,
                email: gm.email || "",
              })
            }
          />
        ))}
        connection={{
          cursorsList,
          totalCount,
          pageInfo: {
            endCursor: response.data.guestMemberships.pageInfo.endCursor,
            startCursor: response.data.guestMemberships.pageInfo.startCursor,
          },
        }}
        headerButtons={
          <div className={classes.headerButtons}>
            <div className={classes.lhsHeader}>
              {/* Search bar */}
              <DiscoTableSearchInput testid={TESTID} onChange={handleSearchChange} />
            </div>

            <div className={classes.rhsHeader}>
              {/* Filter dropdown */}
              <DiscoDropdown
                testid={"CommunityGuestReportTable.filter-select"}
                menuClasses={{
                  paper: classes.filterDropdownMenu,
                }}
                menuButton={(btnProps) => (
                  <DiscoButton
                    {...btnProps}
                    data-testid={"CommunityGuestReportTable.filter-select.header-button"}
                    className={classes.dropdownButton}
                  >
                    {"Filters"}
                  </DiscoButton>
                )}
              >
                <DiscoText
                  variant={"body-xs-500"}
                  marginTop={0.5}
                  marginBottom={0.5}
                  color={"groovy.grey.400"}
                >
                  {"Filter by..."}
                </DiscoText>

                {/* Disco invite dropdown */}
                <CommunityGuestReportTableInviteFilter
                  filterState={toolbarState}
                  setFilterState={(newFilterState) =>
                    setToolbarState({
                      ...toolbarState,
                      ...newFilterState,
                    })
                  }
                />
              </DiscoDropdown>

              {/* Sort */}
              <GuestsSortDropdown
                sortState={toolbarState}
                setSortState={(newSortState) =>
                  setToolbarState({
                    ...toolbarState,
                    ...newSortState,
                  })
                }
              />

              {/* Export to CSV */}
              {!isXsDown && (
                <DiscoButton
                  className={classes.csvButton}
                  color={"grey"}
                  variant={"outlined"}
                  onClick={handleExportCSVClick}
                  shouldDisplaySpinner={isSubmitting}
                  disabled={totalCount === 0}
                >
                  {"Export CSV"}
                </DiscoButton>
              )}
            </div>
          </div>
        }
        header={[
          {
            value: (
              <div className={classes.nameHeader}>
                <CommunityGuestSelectAllCheckbox
                  testid={TESTID}
                  className={classes.checkbox}
                  setSelectedMembershipIds={setSelectedMembershipIds}
                  isAllSelected={isAllSelected}
                  guestMembershipArgs={guestMembershipArgs}
                />
                <DiscoText variant={"body-xs-600"}>{"Guest Name"}</DiscoText>
              </div>
            ),
            key: "Name",
          },
          { value: "Has Access to" },
          { value: "Date Joined" },
          { value: "Disco Invite Sent" },
          { value: "Actions" },
        ]}
        onPaginate={response.refetch}
      />
      <CommunityGuestListFloatingBar
        open={totalSelectedGuests > 0}
        onClose={handleDeselectMemberships}
        guests={Array.from(selectedMembershipIds)}
      />
    </>
  )
  function commitMutation() {
    return createGuestCSVMutation({
      input: {
        organizationId: activeOrganization.id,
        search: toolbarState.search,
        orderBy: toolbarState.sort.order.field as GuestMembershipsOrderBy,
        hasBeenInvited: toolbarState.hasBeenInvited,
        direction: toolbarState.direction as OrderDirection,
      },
    })
  }

  async function handleExportCSVClick() {
    // Call mutation to handle this on the backend, return string
    try {
      setIsSubmitting(true)
      const { response: mutationResponse } = await commitMutation()

      if (mutationResponse.downloadCSVLink) {
        window.location.href = mutationResponse.downloadCSVLink
      }
    } catch (error) {
      displayErrorToast(error)
    }
    setIsSubmitting(false)
  }

  function isMembershipSelected(membership: GuestCheckList) {
    return selectedMembershipIds.some(
      (m) => m.guestMembershipId === membership.guestMembershipId
    )
  }

  function handleDeselectMemberships() {
    setSelectedMembershipIds([])
  }

  function toggleSelectedMembership(membership: GuestCheckList) {
    const isSelected = isMembershipSelected(membership)
    if (isSelected) {
      setSelectedMembershipIds((prev) =>
        [...prev].filter((id) => id.guestMembershipId !== membership.guestMembershipId)
      )
    } else {
      setSelectedMembershipIds((prev) => [...prev, membership])
    }
  }

  function handleSearchChange(value: string) {
    setToolbarState({
      ...toolbarState,
      search: value,
    })
  }

  function refetchGuests() {
    response.refetch(
      {
        first: GUESTS_PER_PAGE,
      },
      { fetchPolicy: "network-only" }
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  headerButtons: {
    display: "flex",
    gap: theme.spacing(1.5),
    flexWrap: "wrap",
    width: "100%",
  },
  lhsHeader: {
    flexGrow: 1,
    minWidth: "240px",
  },
  rhsHeader: {
    display: "flex",
    justifyContent: "flex-start",
    gap: theme.spacing(1.5),
  },
  nameHeader: {
    display: "flex",
    alignItems: "center",
  },
  csvButton: {
    minWidth: "140px",
  },
  checkbox: {
    margin: 0,
  },
  filterDropdownMenu: {
    minWidth: "200px",
  },
  dropdownButton: {
    minWidth: "140px",
    justifyContent: "space-between",
  },
}))

const CommunityGuestReportTableSkeleton: React.FC = () => {
  const classes = useStyles()

  return (
    <PageContent noMargins>
      <DiscoTableSkeletonWithHeader
        headerButtons={
          <div className={classes.headerButtons}>
            <div className={classes.lhsHeader}>
              <DiscoTableSearchInputSkeleton />
            </div>
            <div className={classes.rhsHeader}>
              <DiscoTableSortDropdownSkeleton />
              <DiscoTableSortDropdownSkeleton />
            </div>
          </div>
        }
        header={[
          {
            value: <Skeleton variant={"rect"} width={20} height={20} />,
            key: "checkbox",
          },
          { value: "Guest Name" },
          { value: "Has Access to" },
          { value: "Date Joined" },
          { value: "Disco Invite Sent" },
        ]}
        rows={5}
        row={
          <DiscoTable.Row>
            <TableCell>
              <Skeleton variant={"rect"} width={20} height={20} />
            </TableCell>
            <TableCell width={"40%"}>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
          </DiscoTable.Row>
        }
      />
    </PageContent>
  )
}

export default Relay.withSkeleton({
  component: observer(CommunityGuestReportTable),
  skeleton: CommunityGuestReportTableSkeleton,
})
