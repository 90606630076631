/**
 * @generated SignedSource<<fd633b11d93346d2bac2340ddd6b3a53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventVisibility = "cohort" | "member_group" | "public" | "%future added value";
export type MemberGroupKind = "default" | "custom" | "role" | "test_user" | "%future added value";
export type ZoomConnectionHumanReadableUserPlanType = "basic" | "licensed" | "premium" | "none" | "unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditEventFormFields_Occurrence$data = {
  readonly id: string;
  readonly collectFeedback: boolean;
  readonly visibility: EventVisibility;
  readonly content: {
    readonly name: string | null;
  };
  readonly capacity: number | null;
  readonly guestCapacity: number | null;
  readonly product: {
    readonly id: string;
    readonly organization: {
      readonly id: string;
    };
  };
  readonly meetingUrl: string | null;
  readonly videoRoomType: string;
  readonly meetingProvider: {
    readonly id: string;
    readonly zoomConnection: {
      readonly id: string;
      readonly email: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly picUrl: string | null;
      readonly userPlanType: ZoomConnectionHumanReadableUserPlanType;
      readonly hasWebinarAddon: boolean | null;
      readonly meetingCapacity: number | null;
      readonly webinarCapacity: number | null;
      readonly isConnected: boolean;
    };
    readonly organization: {
      readonly id: string;
      readonly hasZoomIntegration: boolean;
    };
    readonly user: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationZoomConnectionListItemFragment">;
  } | null;
  readonly physicalAddress: {
    readonly displayAddress: string;
    readonly address: string;
    readonly lat: number;
    readonly lng: number;
    readonly placeId: string;
    readonly image: string;
  } | null;
  readonly event: {
    readonly id: string;
    readonly productId: string;
    readonly memberGroupEntityTargets: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly memberGroup: {
            readonly id: string;
            readonly kind: MemberGroupKind;
          } | null;
        };
      }>;
    };
  };
  readonly hosts: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly user: {
          readonly id: string;
          readonly first_name: string | null;
          readonly last_name: string | null;
          readonly avatar: string;
        };
      };
    }>;
  };
  readonly " $fragmentType": "EditEventFormFields_Occurrence";
};
export type EditEventFormFields_Occurrence$key = {
  readonly " $data"?: EditEventFormFields_Occurrence$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditEventFormFields_Occurrence">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditEventFormFields_Occurrence",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "collectFeedback",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "guestCapacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meetingUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoRoomType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MeetingProvider",
      "kind": "LinkedField",
      "name": "meetingProvider",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrganizationZoomConnectionListItemFragment"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ZoomConnection",
          "kind": "LinkedField",
          "name": "zoomConnection",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "picUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userPlanType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasWebinarAddon",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "meetingCapacity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "webinarCapacity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isConnected",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasZoomIntegration",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProfileAvatarWithDetailsFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventPhysicalAddress",
      "kind": "LinkedField",
      "name": "physicalAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lng",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupEntityTargetNodeConnection",
          "kind": "LinkedField",
          "name": "memberGroupEntityTargets",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MemberGroupEntityTargetNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MemberGroupEntityTarget",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MemberGroup",
                      "kind": "LinkedField",
                      "name": "memberGroup",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "kind",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OccurrenceHostNodeConnection",
      "kind": "LinkedField",
      "name": "hosts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceHostNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OccurrenceHost",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": "first_name",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": "last_name",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};
})();

(node as any).hash = "a5b19aecf4f5f6bbf2123316420a05e7";

export default node;
