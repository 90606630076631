/**
 * @generated SignedSource<<d689551beacc9704322ac4e0c3c892a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GuestMembershipsOrderBy = "member_name" | "joined_on" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type CommunityGuestReportTablePaginationQuery$variables = {
  after?: string | null;
  before?: string | null;
  direction?: OrderDirection | null;
  first?: number | null;
  hasBeenInvited?: boolean | null;
  last?: number | null;
  orderBy?: GuestMembershipsOrderBy | null;
  search?: string | null;
  id: string;
};
export type CommunityGuestReportTablePaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"CommunityGuestReportTable_PaginationFragment">;
  } | null;
};
export type CommunityGuestReportTablePaginationQuery = {
  variables: CommunityGuestReportTablePaginationQuery$variables;
  response: CommunityGuestReportTablePaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "direction"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasBeenInvited"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v9 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "direction",
    "variableName": "direction"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "hasBeenInvited",
    "variableName": "hasBeenInvited"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommunityGuestReportTablePaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v10/*: any*/),
            "kind": "FragmentSpread",
            "name": "CommunityGuestReportTable_PaginationFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "CommunityGuestReportTablePaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "GuestMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "guestMemberships",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GuestMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GuestMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasBeenInvited",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fullName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isTest",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserMembershipNodeConnection",
                            "kind": "LinkedField",
                            "name": "userMemberships",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserMembershipNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserMembership",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v12/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "entity",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Occurrence",
                                        "kind": "LinkedField",
                                        "name": "occurrence",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                          },
                                          (v12/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfoObjectType",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "filters": [
                  "search",
                  "orderBy",
                  "direction",
                  "hasBeenInvited"
                ],
                "handle": "connection",
                "key": "CommunityGuestReportTable__guestMemberships",
                "kind": "LinkedHandle",
                "name": "guestMemberships"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c2f790382c5e835459ec7c0c532f3873",
    "id": null,
    "metadata": {},
    "name": "CommunityGuestReportTablePaginationQuery",
    "operationKind": "query",
    "text": "query CommunityGuestReportTablePaginationQuery(\n  $after: String\n  $before: String\n  $direction: OrderDirection\n  $first: Int\n  $hasBeenInvited: Boolean\n  $last: Int\n  $orderBy: GuestMembershipsOrderBy\n  $search: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...CommunityGuestReportTable_PaginationFragment_2MOEDU\n    id\n  }\n}\n\nfragment CommunityGuestReportTable_PaginationFragment_2MOEDU on Organization {\n  id\n  guestMemberships(search: $search, orderBy: $orderBy, direction: $direction, hasBeenInvited: $hasBeenInvited, first: $first, after: $after, last: $last, before: $before) {\n    totalCount\n    edges {\n      cursor\n      node {\n        id\n        ...OrganizationGuestListRow_Member\n        email\n        user {\n          id\n        }\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      startCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n}\n\nfragment OrganizationGuestListActionDropdownFragment on GuestMembership {\n  id\n  email\n  user {\n    id\n    fullName\n  }\n}\n\nfragment OrganizationGuestListRow_Member on GuestMembership {\n  id\n  email\n  createdAt\n  hasBeenInvited\n  ...OrganizationGuestListActionDropdownFragment\n  userMemberships {\n    totalCount\n    edges {\n      node {\n        id\n        entity\n        occurrence {\n          name\n          id\n        }\n      }\n    }\n  }\n  user {\n    ...ProfileAvatarWithDetailsFragment\n    id\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n"
  }
};
})();

(node as any).hash = "1f38cec6e09039133cf42acac3a2487d";

export default node;
